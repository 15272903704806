<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4"
					v-html="'Les Smart Contracts'" />

			<h2>Au-delà de l'immobilier</h2>
			<p>
				Les smart-contracts sont des programmes informatiques de la blockchain qui s'exécutent de façon totalement automatisés.
				<br />Ces smart-contracts sont très sécurisés car personne ne peut en modifier les termes une fois un smart-contrat signé.
			</p>
		</div>


		<div class="my-4"
				 style="height:100%">
			<h2 class="mb-12">Entrer, investir et sortir de l'écosystème Fraction.re via la blockchain Stellar</h2>
			<blockimage bt1="Entrer dans Stellar"
									bt2="Premier investissement"
									bt3="Convertir en Lumens"
									bt4=" "
									bx1="Entrer dans la blockchain Stellar, obtenir vos clés de cryptage et acheter des Lumnens. "
									bx2="Vendre vos Lumens contre des unités de compte et investir dans l'écosysteme  Fraction.re."
									bx3="Convertir ses unités de compte en Lumens pour sortir de l'écosytème Fraction.re."
									bx4=""
									bi1="https://media.berlcoin.fr/sc1.jpg"
									bi2="https://media.berlcoin.fr/sc2.jpg"
									bi3="https://media.berlcoin.fr/sc3.jpg"
									bi4=""
									link1="open"
									link2="wallet"
									link3="exit"
									link4="" />
		</div>



		<div class="my-4"
				 style="height:100%">
			<h2 class="mb-12"><i></i>Investir dans l'écosystème Fraction.re via les contrats de droits français</h2>
			<blockimage bt1="<b class='text-red'>Renseigner vos données KYC/AML</b>"
									bt2="<b class='text-red'>Intégrer les données KYC</b>"
									bt3="<b class='text-red'>Signer un contrat</b>"
									bt4=" "
									bx1="Renseigner vos données personnelles et transférer les documents KYC/AML "
									bx2="Intégrer les données KYC/AML dans la blockchain pour des signatures automatiques."
									bx3="Signez des contrats de droits français en utilisant ou non la blockchain."
									bi1="https://media.berlcoin.fr/sc19.jpg"
									bi2="https://media.berlcoin.fr/sc18.jpg"
									bi3="https://media.berlcoin.fr/sign.jpg"
									link1="francais"
									link2="kyc"
									link3="sign" />
		</div>


		<div class="my-4"
				 style="height:100%">
			<h2 class="mb-12">Acheter, vendre et transferer des tokens immobiliers</h2>
			<blockimage bt1="Acheter"
									bt2="Vendre"
									bt3="Transferer "
									bx1="Acheter des tokens immobiliers sur la place de marché primaire ou secondaire."
									bx2="Vendre vos tokens immobiliers sur la place de marché secondaire."
									bx3="Transferer ses tokens directement à un tiers sans intermédiaire."
									bi1="https://media.berlcoin.fr/sc4.jpg"
									bi2="https://media.berlcoin.fr/sc5.jpg"
									bi3="https://media.berlcoin.fr/sc6.jpg"
									link1="ordreachat"
									link2="sellasset"
									link3="transfert" />
		</div>
		<div class="mb-4"
				 style="height:100%">
			<h2 class="mb-12">Utiliser les Automated Market Maker (AMM)</h2>
			<blockimage bt1="Acheter"
									bt2="Vendre"
									bt3="Liquidity pool"
									bx1="Acheter des tokens immobiliers via un AMM."
									bx2="Vendre des tokens immobiliers via un AMM."
									bx3="Intégrer un Liquidity Pool et faite des dépôts ou des retraits."
									bi1="https://media.berlcoin.fr/sc16.jpg"
									bi2="https://media.berlcoin.fr/sc17.jpg"
									bi3="https://media.berlcoin.fr/sc13.jpg"
									bi4=""
									link1="ammachat"
									link2="sellamm"
									link3="pool" />
			<div class="mb-8"
					 style="height:100%">
				<h2 class="mb-12"></h2>
				<blockimage bt1="Transfert avec garantie d'envoi"
										bt2="Transfert avec garantie de reception"
										bt3=""
										bx1="Tranférer des tokens avec reception d'un autre token avec garantie d'envoi."
										bx2="Tranférer des tokens avec reception d'un autre token avec garantie de reception."
										bx3=""
										bi1="https://media.berlcoin.fr/sc14.jpg"
										bi2="https://media.berlcoin.fr/sc15.jpg"
										bi3=""
										bi4=""
										link1="envoi"
										link2="reception"
										link3="" />
			</div>
		</div>
		<div class="mb-4"
				 style="height:100%">
			<h2 class="mb-12">Smart-contracts spécifiques à l'écosystème fraction.re</h2>


			<blockimage bt1="Sponsoring"
									bt2="Staking"
									bt3="Tiers de confiance"
									bx1="Parainner une connaissance et bénéficier de tokens gratuits."
									bx2="Investir sur le long terme et recevoir des récompenses."
									bx3="Transferer ses tokens avec un tiers de confiance."
									bi1="https://media.berlcoin.fr/sc12.jpg"
									bi2="https://media.berlcoin.fr/sc10.jpg"
									bi3="https://media.berlcoin.fr/sc11.jpg"
									link1="staking"
									link2="staking"
									link3="tdc" />
		</div>
		<div class="mb-4"
				 style="height:100%">
			<h2 class="mb-12">Smart-contracts spécifiques à Stellar</h2>


			<blockimage bt1="Transfert à terme échu"
									bt2="Créer une ligne de confiance"
									bt3="Supprimer une ligne de confiance"
									bx1="Transferer ses tokens à un tiers de façon décentralisé à terme échu en jours."
									bx2="Créer une ligne de confiance pour un token de l'écosystème Fraction.re."
									bx3="Supprimer une ligne de confiance pour un token de l'écosystème Fraction.re."
									bi1="https://media.berlcoin.fr/sc7.jpg"
									bi2="https://media.berlcoin.fr/sc8.jpg"
									bi3="https://media.berlcoin.fr/sc9.jpg"
									link1="transfertterm"
									link2="trustline"
									link3="deleteasset" />
		</div>
	</div>
</section>
</template>

/*
component: () => import('../views/smart/enterstellar.vue')
component: () => import('../views/smart/sellxlm.vue')
component: () => import('../views/smart/outstellar.vue')


component: () => import('../views/smart/ordreachat.vue')
component: () => import('../views/smart/sellasset.vue')
component: () => import('../views/smart/transfert.vue')


component: () => import('../views/smart/ammachat.vue')
component: () => import('../views/smart/sellamm.vue')
component: () => import('../views/smart/pool.vue')

component: () => import('../views/smart/staking.vue')
component: () => import('../views/smart/tiersdeconfiance.vue')
component: () => import('../views/smart/transfertterm.vue')


component: () => import('../views/smart/deleteasset.vue')
 */
<script>
import blockimage from "@/components/blocks_image_3b.vue";

export default {
	name: 'smartcontract',
	components: {
		blockimage
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
